<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="申请单号" v-model="detail.code" :disabled="true"></inputItem>
          <inputItem title="申请人员" v-model="detail.apply_name" :disabled="true"></inputItem>
          <inputItem title="申请日期" :initialContent="formatDate(detail.apply_time)" :disabled="true"></inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="出差明细" label="出差明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="item.id">
              <div class="item-header">
                <div class="item-index">明细{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)"
                  v-if="!disabled || !item.id" />
              </div>
              <inputItem title="相关项目" :initialContent="item.project_name" :disabled="disabled" type="others"
                ref="project_name" :index="String(index)" :otherClicked="clickProject"></inputItem>
              <inputItem title="开始日期" :initialContent="formatDate(item.s_time)" :disabled="disabled" type="date"
                name="s_time" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="结束日期" :initialContent="formatDate(item.e_time)" :disabled="disabled" type="date"
                name="e_time" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="出差人员" v-model="item.users" :disabled="disabled"></inputItem>
              <inputItem title="工作内容" v-model="item.content" :disabled="disabled" type="textarea"></inputItem>
              <inputItem title="差旅城市" v-model="item.city" :disabled="disabled"></inputItem>
              <inputItem title="住宿酒店" v-model="item.hotel" :disabled="disabled"></inputItem>
              <inputItem title="交通工具" type="select" :options="trafficOption" v-model="item.traffic"
                :disabled="disabled"></inputItem>
              <inputItem title="备注" v-model="item.remark" :disabled="disabled"></inputItem>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addItem"> 添加明细 </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step" v-if="detail && detail.state > 1">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName"
                :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')">
              </Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawer" v-if="showOptions" :onBgClicked="onBgClicked" :onSearch="onSearch"
      :items="projects" @optionClicked="optionClicked"> </optionDrawer>
  </div>
</template>

<script>
import { GetTravel, AuditTravel, SaveTravel, CancelTravel } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction, loadDataOptionAction, loadProjectOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
      projects: [],
      showOptions: false,
      disabled: false,
      trafficOption: [],
      cityOption: [],
      itemIndex: -1,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    addItem: function () {
      this.items.push({ id: Math.random(), users: this.$store.state.user.realName });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
    },
    onSearch: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClicked: function () {
      this.showOptions = false;
    },
    optionClicked: function (item) {
      this.items[this.itemIndex].project_id = item.id;
      this.items[this.itemIndex].project_name = item.name;
      this.$refs.project_name[this.itemIndex].setContent(item.name);
      this.showOptions = false;
    },
    clickProject: function (index) {
      this.itemIndex = Number(index);
      this.showOptions = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawer.show(); }, 100); });
    },
    itemChanged: function (name, index, value) {
      switch (name) {
        case "s_time":
          this.items[Number(index)].s_time = value;
          break;
        case "e_time":
          this.items[Number(index)].e_time = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveTravel(1);
          break;
        case "提交审核":
          this.saveTravel(2);
          break;
        case "审核通过":
          this.auditTravel(true, "同意");
          break;
        case "审核拒绝":
          this.auditTravel(false, "");
          break;
        case "撤回":
          this.cancelTravel();
          break;
      }
    },
    saveTravel: function (state) {
      this.detail.state = state;
      submitAction(this, SaveTravel, { model: this.detail, items: this.items }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditTravel: function (pass, memo) {
      auditAction(this, AuditTravel, this.id, pass, memo, null, null, this.items);
    },
    cancelTravel: function () {
      cancelAction(this, CancelTravel, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetTravel, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || (this.buttons.indexOf("保存草稿") < 0 && this.buttons.indexOf("审核通过") < 0);
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "交通工具", (data) => { this.trafficOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
